.popup_block {
  padding: 32px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.box_texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.box_texts__title {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Gilroy, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}
.box_texts__title > span {
  color: #D92D20;
}
.box_texts__subtitle {
  color: #BEBEBE;
  font-family: "Gotham Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.box_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.box_form__box {
  width: 100%;
}
.box_form__box_wrap {
  border-radius: 10px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
}
.box_form__box_wrap__inp {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
  padding-left: 3px;
}
.box_form__box_wrap__inp::placeholder {
  color: #667085;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.box_form__box_wrap__telInp {
  width: 100%;
  height: 24px;
}
.box_form__box_wrap__telInp > :nth-child(1) > :nth-child(1) {
  border: none !important;
  height: 24px !important;
}
.box_form__box_wrap__telInp > :nth-child(2) {
  height: 24px !important;
  width: 100%;
  border: none !important;
  color: #7E818B !important;
  font-family: "Gilroy", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  border-radius: 6px !important;
}
.box_form__btn {
  padding-right: 70px;
  padding-left: 70px;
  width: 100% !important;
  max-width: 240px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 8px;
  border-radius: 9999px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  background: #0085FF;
  color: #FFF;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none !important;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}
.box_form__btn:hover {
  background: #067EEC;
  color: #FFF;
}
.box_form__btn:hover .box_form__btn_arrow > path {
  fill: #FFF;
}
.box_form__btn:active {
  background: #067EEC;
}

.popup_close_btn {
  background-color: white;
  border: none;
  position: absolute;
  right: 1%;
  top: 0%;
}

.thank_title {
  color: var(--colors-text-text-primary-900, #101828);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.thank_text {
  color: var(--colors-text-text-tertiary-600, #475467);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.link_decoration {
  text-decoration: none !important;
}

@media (max-width: 720px) {
  .box {
    align-items: center;
    gap: 24px;
  }
  .box_texts {
    display: flex;
    align-items: center;
  }
  .box_texts__title {
    font-size: 25px;
    letter-spacing: 0.25px;
    line-height: 112%;
    align-self: stretch;
  }
  .box_texts__subtitle {
    font-size: 18px;
    align-self: stretch;
  }
  .box_form {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 20px;
  }
  .box_form__btn {
    width: 100% !important;
    max-width: 240px;
  }
  .popup_block {
    padding: 20px 20px;
  }
  .popup_close_btn {
    top: 5%;
  }
  .popup_block_btn {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 0px;
  }
}

