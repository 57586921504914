.popup_block
  padding: 32px

.wrap
  //padding: 32px
  //background-image: url("../../Assets/Images/Form/bg_form.webp")
  //background-size: cover
  //background-repeat: no-repeat

.box
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  gap: 20px
  &_texts
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 6px
    &__title
      color: var(--colors-text-text-primary-900, #101828)
      font-family: Gilroy, sans-serif
      font-size: 28px
      font-style: normal
      font-weight: 600
      line-height: 32px
      margin: 0

      & > span
        color: #D92D20
    &__subtitle
      color: #BEBEBE
      font-family: 'Gotham Pro', sans-serif
      font-size: 20px
      font-style: normal
      font-weight: 400
      line-height: 120%
  &_form
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    gap: 10px
    &__box
      width: 100%
      &_wrap
        border-radius: 10px
        border: 1px solid #D0D5DD
        background: #FFF
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)
        padding: 10px 12px
        display: flex
        align-items: center
        gap: 8px
        height: 44px
        &__inp
          width: 100%
          outline: none
          border: none
          border-radius: 6px
          padding-left: 3px
          &::placeholder
            color: #667085
            font-family: "Gilroy", sans-serif
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: 18px
        &__telInp
          width: 100%
          height: 24px
          & > :nth-child(1)
            & > :nth-child(1)
              border: none!important
              height: 24px!important
          & > :nth-child(2)
            height: 24px!important
            width: 100%
            border: none!important
            color: #7E818B!important
            font-family: "Gilroy", sans-serif!important
            font-size: 14px!important
            font-style: normal!important
            font-weight: 400!important
            line-height: 18px!important
            border-radius: 6px!important
    &__btn
      padding-right: 70px
      padding-left: 70px
      width: 50% !important
      height: 40px
      display: flex
      align-items: center
      justify-content: center
      white-space: nowrap
      gap: 8px
      border-radius: 9999px
      border: 2px solid rgba(255, 255, 255, 0.12)
      box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
      background: #0085FF
      color: #FFF
      font-family: 'Gilroy', sans-serif
      font-size: 14px
      font-style: normal
      font-weight: 600
      line-height: 20px
      transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease
      &:hover
        background: #067EEC
        color: #FFF
        & .box_form__btn_arrow > path
          fill: #FFF
      &:active
        background: #067EEC

.popup_close_btn
  background-color: white
  border: none
  position: absolute
  right: 1%
  top: 0%

.form_box_label
  color: #344054
  font-family: "Gilroy", sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 18px
  margin-bottom: 6px

.popup_block_btn
  width: 100%
  display: flex
  gap: 8px
  margin-top: 10px

.popup_close_button
  display: flex
  height: 40px
  padding: var(--spacing-md, 8px) var(--spacing-xl, 16px)
  justify-content: center
  align-items: center
  gap: var(--spacing-xs, 4px)
  flex: 1 0 0
  border-radius: var(--radius-full, 9999px)
  border: 1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)
  background: var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
  color: var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-sm, 14px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-sm, 20px)


@media (max-width: 720px)
  .box
    align-items: stretch
    gap: 24px
    &_texts
      display: flex
      align-items: center
      &__title
        font-size: 25px
        letter-spacing: 0.25px
        line-height: 112%
        align-self: stretch
      &__subtitle
        font-size: 18px
        align-self: stretch
    &_form
      flex-direction: column
      align-items: stretch
      justify-content: center
      gap: 20px
      &__btn
        width: 50% !important



  .popup_block
    padding: 20px 20px

  .popup_close_btn
    top: 5%

  .popup_block_btn
    width: 100%
    display: flex
    gap: 8px
    margin-top: 0px
