//.modal-header
//  border: none!important
//
//.modal-body
//  padding: 0!important
//
.modal-content
  border-radius: 20px!important
//
//@media (max-width: 720px)
//  .modal-content
//    padding: 32px 20px!important

.modal-body
  padding: 0!important
  