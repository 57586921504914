.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
  background: var(--Colors-Background-bg-secondary, #F9FAFB);
}

.image {
  margin-top: 64px;
  width: 70%;
  height: 65vh;
  background-image: url("../../../Assets/Images/HowItWorks/HowItWorks.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 720px) {
  .image {
    background-image: url("../../../Assets/Images/HowItWorks/HowItWorksMb.webp");
    width: 90%;
    background-size: 100% 100%;
    aspect-ratio: 1/2;
    height: auto;
  }
}

