.footer_block
  padding: var(--spacing-7xl, 64px) var(--spacing-4xl, 32px)
  background: var(--Colors-Background-bg-secondary, #F9FAFB)

.footer_content_block
  display: flex
  flex-direction: column
  gap: 20px

.footer_top_block
  display: flex
  flex-direction: row
  gap: 20px
  justify-content: space-between

.footer_top_left
  display: flex
  flex-direction: row
  gap: 40px
  flex-wrap: wrap

.footer_top_right
  display: flex
  flex-direction: column
  align-items: flex-end
  gap: 12px

.footer_bottom_block
  display: flex
  flex-direction: row
  justify-content: space-between
  border-top: 1px solid var(--Colors-Border-border-secondary, #E4E7EC)
  padding-top: 32px
  align-items: center

.footer_bottom_copiryght
  color: var(--colors-text-text-quaternary-500, #667085)
  font-family: Gilroy, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 18px
  margin: 0

.footer_nav_link_text
  color: var(--Component-colors-Components-Buttons-Tertiary-button-tertiary-fg, #475467)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-md, 16px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-md, 24px)
  margin: 0
  text-decoration: none
  cursor: pointer

.footer_nav_link_text:hover
  color: #344054
  
  
.footer_contact_text
  color: var(--colors-text-text-tertiary-600, #475467)
  font-family: Gilroy, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 18px
  margin: 0
  text-decoration: none

.footer_logo
  width: 106px
  height: auto
  
@media (max-width: 1200px)
  .footer_top_left
    gap: 20px

  .footer_top_block
    gap: 70px

@media (max-width: 1200px)
  .footer_top_left
    gap: 20px
    justify-content: space-between

  .footer_top_block
    gap: 24px
    flex-direction: column-reverse
    align-items: center

  .footer_top_right
    align-items: center


.footer_nav_link_text
  font-size: var(--Font-size-text-md, 14px)
