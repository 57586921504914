.container
  text-align: center
  padding: 40px 0
  background-image: url("../../../Assets/Images/Advantages/AdvantagesBg.webp")
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.content 
  display: flex
  justify-content: center
  margin-top: 88px
  gap: 10%
  width: 100%

.desc
  padding: 7px 16px
  border-radius: var(--radius-full, 9999px)
  background: rgba(209, 227, 255, 0.30)
  color: #1586EF
  margin-bottom: 12px
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-sm, 14px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-xs, 18px) 
.title
  color: var(--colors-text-text-primary-900, #101828)
  text-align: center
  width: 700px
  margin: auto
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-display-md, 36px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-display-md, 44px) /* 122.222% */
  letter-spacing: -0.72px
.card 
  background: transparent
  border-radius: 10px
  width: 320px
  text-align: center


.beforeTitle
  color: var(--Component-colors-Utility-Error-utility-error-50, #FEF3F2)
  text-align: center
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-md, 16px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-xs, 18px)
  background-color: #ff4f4f
  padding: 10px 20px
  border-radius: 50px
  position: relative
  display: inline-block
  margin-bottom: 20px
  &::after
    content: ''
    position: absolute
    bottom: -10px
    left: 50%
    transform: translateX(-50%)
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-top: 10px solid #ff4f4f

.afterTitle
  color: var(--Component-colors-Utility-Error-utility-error-50, #FEF3F2)
  text-align: center
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-md, 16px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-xs, 18px)
  background-color: #4caf50
  position: relative
  padding: 10px
  border-radius: 50px
  display: inline-block
  margin-bottom: 20px
  &::after
    content: ''
    position: absolute
    bottom: -10px
    left: 50%
    transform: translateX(-50%)
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-top: 10px solid #4caf50

.image 
  max-width: 100%
  height: auto
  margin-bottom: 20px


.infoText
  color: var(--colors-text-text-primary-900, #101828)
  text-overflow: ellipsis
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-md, 16px)
  font-style: normal
  font-weight: 500
  &_mod
    padding: 20px
    color: var(--colors-text-text-quaternary-500, #667085)
    text-align: center
    font-family: Gilroy, sans-serif
    font-size: var(--Font-size-text-xs, 12px)
    font-style: normal
    font-weight: 400
  &_sub
    overflow: hidden
    color: var(--colors-text-text-tertiary-600, #475467)
    text-overflow: ellipsis
    font-family: Gilroy, sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 20px

.decrease
  color: #EF2214
  font-family: Inter, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 20px


.increase
  color: #10CC5E
  font-family: Inter, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 20px

@media(max-width: 720px)
  .container
    background-image: url("../../../Assets/Images/Advantages/AdvantagesMbBg.webp")
  .title
    width: auto
    font-size: 24px
    padding: 0 40px
  .content
    margin-top: 40px
    gap: 10px
  .card
    width: 45%
  .image
    margin-bottom: 10px
  .infoText
    font-size: 10px
    &_sub
      font-size: 8px
    &_mod
      font-size: 8px
  .decrease, .increase
    font-size: 8px