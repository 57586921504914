.faq_block
  padding: 64px 0
  background: #FFF

.faq_title_block
  margin-bottom: 48px

.faq_title
  color: var(--colors-text-text-primary-900, #101828)
  text-align: center
  font-family: Gilroy, sans-serif
  font-size: 36px
  font-style: normal
  font-weight: 600
  line-height: 44px
  letter-spacing: -0.72px
  margin: 0

.faq_block
  padding-bottom: 134px

.faq_heading_block
  margin-bottom: 79px

.faq_heading
  color: var(--565656, #565656)
  text-align: center
  font-family: Gilroy, sans-serif
  font-size: 100px
  font-style: normal
  font-weight: 600
  line-height: normal
  letter-spacing: -7px
  margin-bottom: 29px

.faq_subheading
  color: var(--565656, #565656)
  text-align: center
  font-family: Gilroy, sans-serif
  font-size: 24px
  font-style: normal
  font-weight: 500
  line-height: normal

.accordion-button:focus
  outline: 0 !important
  box-shadow: none !important

.accordion
  border: 1px rgba(208, 213, 221, 0.50)!important

.accordion-button:not(.collapsed)
  box-shadow: none!important

.accordion-item
  margin-bottom: 0 !important
  border-bottom: 1px solid rgba(208, 213, 221, 0.50)!important
  border-left: none!important
  border-right: none!important
  border-radius: 0 !important
  border-top: none!important
  background:  #FFF !important
  padding-bottom: 12px!important

.accordion-button
  background:  #FFF !important
  color: #101828!important
  font-family: Open Sauce One, sans-serif!important
  font-size: 18px!important
  font-style: normal!important
  font-weight: 500!important
  line-height: normal!important
  padding: 24px 0 12px 0 !important
  transition: border-radius 0.3s ease
  border-radius: 8px !important
  gap: 20px


.accordion-body
  background-color: #FFF !important
  color: #475467!important
  font-family: Gilroy, sans-serif!important
  font-size: 16px!important
  font-style: normal!important
  font-weight: 400!important
  line-height: normal!important
  padding: 0 50px 12px 0px !important
  border-radius: 8px !important


.react-bootstrap-accordion-element:focus,
.react-bootstrap-accordion-element:active
  outline: none !important
  border-bottom: none !important

.accordion-button::after
  content: '' !important
  display: inline-block !important
  background-image: url('../../../Assets/Icons/Faq/arrow_more.svg') !important
  background-repeat: no-repeat!important
  width: 24px !important
  height: 24px !important
  background-size: cover !important
  transition: transform 0.9s ease, background-image 0.1s ease !important


.accordion-button:not(.collapsed)::after
  background-image: url('../../../Assets/Icons/Faq/arrow_close.svg') !important
  transform: rotate(180deg) !important
  background-repeat: no-repeat!important
  width: 24px !important
  height: 24px !important

//.accordion-button:hover::after
//  background-image: url('../../../Assets/Icons/Faq/arrow_close.svg') !important
//  transform: rotate(180deg) !important
//  transition: transform 0.9s ease, background-image 0.1s ease !important



@media (max-width: 1440px)
  .faq_block
    padding: 64px 0
    background: #FFF

  .accordion-button
    font-size: 18px!important
    gap: 20px!important

  .accordion-body
    font-size: 16px!important


@media (max-width: 720px)

  .faq_title
    font-size: 24px
    line-height: 28px

  .faq_title_block
    margin-bottom: 40px

  .faq_block
    padding: 64px 0
    background: #FFF

  .accordion-item
   margin-bottom: 0 !important

  .accordion-button
    font-size: 15px!important
    padding: 18px 0 10px 0!important
    gap: 10px!important

  .accordion-body
    font-size: 14px!important
    line-height: 128% !important
    padding: 0 50px 12px 0px !important

  .accordion-button::after
    width: 24px !important
    height: 24px !important

  .accordion-button:not(.collapsed)::after
    width: 24px !important
    height: 24px !important

