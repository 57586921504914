.reviewCard {
  padding: 53px 55px;
  border-radius: 8px;
  max-width: 600px;
  position: relative;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top {
  position: absolute;
  top: 0;
  left: 0;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.header {
  display: flex;
  align-items: center;
  margin: 0;
}

.rating {
  border-radius: var(--radius-full, 9999px);
  background: #F9FAFB;
  padding: 4px 8px;
  width: auto;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.info {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.position {
  font-size: 14px;
  color: gray;
  margin: 0;
}

.rating {
  margin: 0;
}

.star {
  color: #ffc107;
  margin: 0;
}

.text {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.32px;
  margin: 0;
}

@media (max-width: 720px) {
  .reviewCard {
    width: 100%;
    gap: 20px;
    padding: 16px;
  }
  .top, .bottom {
    display: none;
  }
  .name {
    font-size: 16px;
  }
  .position {
    font-size: 12px;
  }
  .text {
    font-size: 14px;
  }
}

