.container
  width: 600px
  display: flex
  align-items: flex-start
  flex-direction: column
  justify-content: center
  margin: 0
.subtitleContainer
  display: flex
  align-items: center
  justify-content: flex-start
  border-radius: var(--radius-full, 9999px)
  border: 1px solid #1586EF
  gap: 10px
  background: #FFF
  height: 32px
  position: relative
  width: auto
  margin-bottom: 24px
.subtitleImage
  position: absolute
  left: -1px
  top: -1px
.subtitle
  margin: 0
  padding: 7px 16px 7px 42px
  color: #1586EF
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-sm, 14px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-xs, 18px) /* 128.571% */
.title
  color: var(--colors-text-text-primary-900, #101828)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-display-md, 36px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-display-sm, 38px) /* 105.556% */
  margin-bottom: 8px
.description
  color: var(--colors-text-text-tertiary-600, #475467)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-lg, 18px)
  font-style: normal
  font-weight: 400
  line-height: var(--Line-height-text-lg, 28px) /* 155.556% */
.benefitsList
  padding: 0 0 0 16px
  margin-bottom: 42px
.benefitItem
  color: var(--colors-text-text-tertiary-600, #475467)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-md, 16px)
  font-style: normal
  font-weight: 400
  line-height: var(--Line-height-text-md, 24px) /* 150% */
  margin: 0 0 16px 6px

.servicesText
  color: var(--Colors-Foreground-fg-disabled, #98A2B3)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-lg, 18px)
  font-style: normal
  font-weight: 400
  line-height: var(--Line-height-text-lg, 28px)
  margin: 0
.services
  display: flex
  align-items: center
  gap: 24px
  margin-bottom: 42px
.serviceImage
  height: 52px
.button
  border-radius: var(--radius-full, 9999px)
  border: 2px solid rgba(255, 255, 255, 0.12)
  background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #0085FF)
  box-shadow: 0 0 0 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
  display: flex
  align-items: center
  gap: 12px
  padding: 12px 20px
  color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-lg, 18px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-lg, 28px) /* 155.556% */
  text-decoration: none
@media(max-width: 720px)
  .container
    width: 100%
    order: 1
  .subtitleContainer
    margin-bottom: 20px
  .title
    font-size: 24px
  .description
    font-size: 14px
  .benefitsList
    padding: 0
    margin-bottom: 24px
  .benefitItem
    font-size: 14px
  .servicesText
    font-size: 14px
  .services
    margin-bottom: 24px
  .serviceImage
    height: 39px
  .button
    font-size: 14px
    padding: 12px 22px
    svg
      display: none
