.integration_block_padding
  padding-top: 32px
  padding-bottom: 80px

.integration_block
  padding: 64px 32px
  background-image: url("../../../Assets/Images/Integration/integration_bg.webp")
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  border-radius: var(--radius-3xl, 20px)

.integration_title
  color: var(--Colors-Text-text-white, #FFF)
  text-align: center
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-display-sm, 30px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-display-sm, 38px)
  margin-bottom: 40px
  
.integration_img_block
  display: flex
  flex-direction: row
  justify-content: center
  flex-wrap: wrap
  gap: 68px

.integration_img
  width: auto
  height: 56px

@media (max-width: 1440px)
  .integration_img_block
    gap: 48px

@media (max-width: 1200px)
  .integration_img
    width: auto
    height: 46px

@media (max-width: 720px)
  .integration_block_padding
    padding-top: 0
    padding-bottom: 0

  .integration_block
    padding: 40px 20px
    border-radius: var(--radius-3xl, 0px)

  .integration_title
    font-size: var(--Font-size-display-sm, 24px)
    line-height: var(--Line-height-display-sm, 28px)
    margin-bottom: 40px

  .integration_img_block
    gap: 24px
    justify-content: center

  .integration_img
    width: auto
    height: 24px

