.main_block
  padding: 80px 0
  background-image: url("../../../Assets/Images/MainBlock/mainblock_bg.webp")
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  //background: #030317

.main_content
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 132px
  align-items: flex-start

.main_content_top
  display: flex
  max-width: 920px
  flex-direction: column
  align-items: flex-start
  gap: var(--spacing-2xl, 20px)

.mainblock_title
  color: #FFF
  font-family: Gilroy, sans-serif
  font-size: 52px
  font-style: normal
  font-weight: 700
  line-height: normal
  letter-spacing: 1.56px
  margin: 0

.mainblock_title_span
  color: #0085FF

.mainblock_title_text
  color: var(--Colors-Foreground-fg-disabled, #98A2B3)
  font-family: Gilroy, sans-serif
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: 30px
  margin: 0

.main_block_buttons
  display: flex
  flex-direction: row
  gap: 12px
  margin-top: 30px

.main_btn_try
  display: flex
  padding: var(--spacing-lg, 12px) var(--spacing-2xl, 20px)
  justify-content: center
  align-items: center
  gap: 10px
  height: 52px
  border-radius: var(--radius-full, 9999px)
  border: 2px solid rgba(255, 255, 255, 0.12)
  background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #0085FF)
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
  color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF)
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-lg, 18px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-lg, 28px)
  transition: background 0.3s ease

.main_btn_try:hover
  background-color: #067EEC


.main_btn_more
  display: flex
  padding: var(--spacing-lg, 12px) var(--spacing-2xl, 20px)
  justify-content: center
  align-items: center
  gap: 10px
  height: 52px
  border-radius: var(--radius-full, 9999px)
  border: 1px solid var(--Component-colors-Alpha-alpha-white-10, rgba(255, 255, 255, 0.10))
  background: var(--Component-colors-Alpha-alpha-white-10, rgba(255, 255, 255, 0.10))
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
  color: var(--Component-colors-Alpha-alpha-white-80, rgba(255, 255, 255, 0.80))
  font-family: Gilroy, sans-serif
  font-size: var(--Font-size-text-lg, 18px)
  font-style: normal
  font-weight: 600
  line-height: var(--Line-height-text-lg, 28px)
  transition: background 0.3s ease

.main_btn_more:hover
  background: var(--Component-colors-Alpha-alpha-white-10, rgba(255, 255, 255, 0.20))

.main_text_uplogo
  color: var(--Colors-Text-text-white, #FFF)
  font-family: Gilroy, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 300
  line-height: 24px
  margin-bottom: 12px

.main_logo_block
  display: flex
  flex-direction: row
  gap: 52px

.main_logo_img
  width: auto
  height: 34px

@media (max-width: 1200px)
  .main_content
    gap: 102px

  .main_content_top
    max-width: 650px

  .main_logo_block
    gap: 22px

  .main_logo_img
    width: auto
    height: 24px

@media (max-width: 720px)
  .main_block
    padding: 100px 0
    background-image: url("../../../Assets/Images/MainBlock/main_bg_mobile.webp")
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    height: 800px

  .main_logo_block
    gap: 22px
    display: none

  .main_text_uplogo
    display: none

  .mainblock_title
    font-size: 28px
    letter-spacing: 0.84px
    margin: 0

  .mainblock_title_text
    font-size: 16px
    line-height: 18px

  .main_btn_try
    font-size: var(--Font-size-text-lg, 14px)
    line-height: var(--Line-height-text-lg, 20px)

  .main_btn_more
    font-size: var(--Font-size-text-lg, 14px)
    line-height: var(--Line-height-text-lg, 20px)

  .main_content
    gap: 0px

  .main_block_buttons
    margin-top: 10px

  .main_content_top
    align-items: center


@media (max-width: 390px)
  .main_block
    padding: 80px 0
    background-image: url("../../../Assets/Images/MainBlock/main_bg_mobile.webp")
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    height: 750px

@media (max-width: 380px)
  .main_block
    padding: 70px 0
    height: 700px

  .main_block_buttons
    margin-top: 0
  