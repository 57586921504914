.wrap {
  display: flex;
  align-items: center;
  gap: 16px;
}
.wrap_text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.wrap_text__title {
  color: #19191C;
  font-family: "TT Norms", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
}
.wrap_text__subTitle {
  color: #7E818B;
  font-family: "TT Norms", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 0;
}

:global(.Toastify__toast-container) {
  min-width: 500px !important;
}

@media (max-width: 1820px) {
  .wrap {
    gap: 12px;
  }
  .wrap_text {
    gap: 4px;
  }
  .wrap_text__title {
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
  }
  .wrap_text__subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
  :global(.Toastify__toast-container) {
    min-width: 400px !important;
  }
}
@media (max-width: 1200px) {
  .wrap {
    gap: 12px;
  }
  .wrap_text {
    gap: 4px;
  }
  .wrap_text__title {
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
  }
  .wrap_text__subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
  :global(.Toastify__toast-container) {
    min-width: 400px !important;
  }
}
@media (max-width: 720px) {
  .wrap {
    gap: 12px;
  }
  .wrap_text {
    gap: 4px;
  }
  .wrap_text__title {
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
  }
  .wrap_text__subTitle {
    display: none;
  }
  :global(.Toastify__toast-container) {
    min-width: 320px !important;
  }
}

