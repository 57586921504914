.promoBlock {
  display: flex;
  align-items: center;
  padding: 85px 0;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBlock {
  max-width: 500px;
}

.icon {
  margin-bottom: 20px;
}
.icon svg {
  height: 56px;
  width: 56px;
  padding: 14px;
  border-radius: var(--radius-full, 9999px);
  background: var(--Colors-Background-bg-brand-secondary, #D1E3FF);
}

.title {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Gilroy, sans-serif;
  font-size: var(--Font-size-display-md, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-display-sm, 38px);
  margin: 0;
}

.description {
  font-size: 16px;
  color: #667085;
  margin-bottom: 25px;
}
.description span {
  color: #3056d3;
  text-decoration: none;
}

.button {
  text-decoration: none;
  border-radius: var(--radius-full, 9999px);
  border: 2px solid var(--Gradient-skeuemorphic-gradient-border, rgba(255, 255, 255, 0.12));
  background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #0085FF);
  box-shadow: 0 0 0 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  padding: 12px 22px;
  color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);
  font-family: Gilroy, sans-serif;
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-sm, 20px);
}
.button:hover {
  background-color: #1d4ed8;
}

.laptopImage {
  width: 900px;
  height: 440px;
  position: absolute;
  transform: translateX(60%);
}

@media (max-width: 720px) {
  .title {
    font-size: 24px;
  }
  .description {
    font-size: 14px;
  }
  .content {
    flex-direction: column;
  }
  .laptopImage {
    width: 130%;
    height: auto;
    position: relative;
    transform: translateX(7%);
    object-fit: contain;
    object-position: left;
  }
}

