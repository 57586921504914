//.wrapHidden
  //animation: slideOut 0.4s ease-out
  //transform: translateY(-100%)
//.wrapShow
//  animation: slideIn 0.4s ease-in

.wrap
  //position: sticky
  top: 0
  background: #030317
  z-index: 1000
.container
  display: flex
  align-items: center
  justify-content: space-between
  padding: 19px 0
  &_logo
    width: 106px
  &_linksWrap
    display: flex
    align-items: center
    margin: 0
    padding: 0
    gap: 30px
    text-decoration: none
    &__link
      color: #FFF
      font-family: Open Sauce One, sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 500
      line-height: normal
      cursor: pointer
      text-decoration: none
      transition: color 0.3s ease, text-decoration 0.3s ease
      &:hover
        color: #FECB00
        text-decoration: underline
        text-underline-offset: 5px
  &_btn
    display: flex
    height: auto
    padding: 10px var(--spacing-xl, 16px)
    justify-content: center
    align-items: center
    color: #FFF
    font-family: Open Sauce One, sans-serif
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: 24px
    border-radius: var(--radius-full, 9999px)
    border: 2px solid rgba(255, 255, 255, 0.12)
    background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #0085FF)
    box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
    margin-left: 30px
    transition: background 0.3s ease, text-decoration 0.3s ease
    &:hover
      background-color: #067EEC

.header_btn
  margin-left: 6px

.header_nav_block
  display: flex

.activeLink
  color: #FECB00
  text-decoration: underline
  text-underline-offset: 5px



@keyframes slideIn
  0%
    transform: translateY(-100%)
  100%
    transform: translateY(0)
@keyframes slideOut
  0%
    transform: translateY(0)
  100%
    transform: translateY(-100%)


@media (max-width: 1200px)
  .container
    display: flex
    align-items: center
    justify-content: space-between
    padding: 23px 0
    &_logo
      width: 140px
    &_linksWrap
      display: flex
      align-items: center
      margin: 0
      padding: 0
      gap: 25px
      &__link
        color: #FFF
        font-family: Open Sauce One, sans-serif
        font-size: 12px
        font-style: normal
        font-weight: 500
        line-height: normal
        cursor: pointer
    &_btn
      font-size: 12px
@media (max-width: 720px)
  .container
    display: none
