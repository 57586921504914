.wrap {
  top: 0;
  background: #030317;
  z-index: 1000;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 0;
}
.container_logo {
  width: 106px;
}
.container_linksWrap {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 30px;
  text-decoration: none;
}
.container_linksWrap__link {
  color: #FFF;
  font-family: Open Sauce One, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}
.container_linksWrap__link:hover {
  color: #FECB00;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.container_btn {
  display: flex;
  height: auto;
  padding: 10px var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Open Sauce One, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: var(--radius-full, 9999px);
  border: 2px solid rgba(255, 255, 255, 0.12);
  background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #0085FF);
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  margin-left: 30px;
  transition: background 0.3s ease, text-decoration 0.3s ease;
}
.container_btn:hover {
  background-color: #067EEC;
}

.header_btn {
  margin-left: 6px;
}

.header_nav_block {
  display: flex;
}

.activeLink {
  color: #FECB00;
  text-decoration: underline;
  text-underline-offset: 5px;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@media (max-width: 1200px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 0;
  }
  .container_logo {
    width: 140px;
  }
  .container_linksWrap {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 25px;
  }
  .container_linksWrap__link {
    color: #FFF;
    font-family: Open Sauce One, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  .container_btn {
    font-size: 12px;
  }
}
@media (max-width: 720px) {
  .container {
    display: none;
  }
}

