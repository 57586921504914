.container
  display: flex
  align-items: center
  gap: 48px
  position: relative
  padding: 64px 0 48px 0
.wrap
  justify-content: center
  display: flex
  align-items: center
  gap: 48px
  position: relative
  padding: 64px 0
.hidden
  overflow: hidden
.image
  position: absolute
  width: 829px
  height: 512px
  transform: translate(90%, 0%)
.imageIcon
  width: 50%
@media (max-width: 1200px)
  .imageIcon
    display: none
@media(max-width: 720px)
  .container
    padding: 40px 0 32px 0
    flex-direction: column
  .image
    position: relative
    transform: translate(0%, 0%)
    width: 100%
    height: auto
  .wrap
    padding: 0
