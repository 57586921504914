.wrap {
  position: fixed;
  top: 0;
  background: #FFF;
  backdrop-filter: blur(25px);
  border-bottom: 1px solid rgba(234, 236, 240, 0.25);
  z-index: 1000;
  width: 100%;
  padding: 3px 0;
}
.wrap_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
  gap: 31%;
}
.wrap_container_logo {
  width: 90px;
}
.wrap_container_closeBtn {
  margin: 20px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.wrap_container_closeText {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.wrap_container_modalWrap {
  padding: 20px 0 0;
  margin: 56px 0 46px;
  border-radius: 0.35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.wrap_container_modalWrap__link {
  color: #475467;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.menu_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.menu_block_text {
  color: var(--colors-text-text-secondary-700, #344054);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.mob_nav_contact_block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.mob_nav_contact {
  color: var(--colors-text-text-tertiary-600, #475467);
  font-family: Gilroy, sans-serif;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

@media (min-width: 721px) {
  .wrap {
    display: none;
  }
}

