.whyus_slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.whyus_slide_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 450px;
}

.whyus_slide_img {
  width: auto;
  height: 442px;
  position: absolute;
  transition: transform 0.5s ease, opacity 0.5s ease, filter 0.5s ease;
}

.whyus_slide_img.active {
  transform: translateX(0) scale(1);
  filter: none;
  opacity: 1;
  z-index: 2;
}

.whyus_slide_img.left {
  transform: translateX(-720px) scale(0.8);
  filter: blur(5px);
  opacity: 0.5;
  z-index: 1;
}

.whyus_slide_img.right {
  transform: translateX(720px) scale(0.8);
  filter: blur(5px);
  opacity: 0.5;
  z-index: 1;
}

.prev {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.next {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.prev {
  left: 28%;
  z-index: 10;
  background-image: url("../../../Assets/Images/WhyUs/prev_arrow.png");
}

.next {
  right: 28%;
  z-index: 10;
  background-image: url("../../../Assets/Images/WhyUs/next_arrow.png");
}

.whyus_block {
  padding: 80px 0;
}

.whyus_title_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
}

.whyus_subtitle {
  display: flex;
  height: 32px;
  padding: var(--spacing-xs, 4px) var(--spacing-xl, 16px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--radius-full, 9999px);
  background: rgba(209, 227, 255, 0.3);
}

.whyus_title {
  color: var(--colors-text-text-primary-900, #101828);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: var(--Font-size-display-md, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-display-md, 44px);
  letter-spacing: -0.72px;
}

.whyus_subtitle_text {
  color: #1586EF;
  font-family: Gilroy, sans-serif;
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-xs, 18px);
  margin: 0;
}

@media (max-width: 1700px) {
  .prev {
    left: 25%;
  }
  .next {
    right: 25%;
  }
}
@media (max-width: 1440px) {
  .whyus_slide_img.left {
    transform: translateX(-670px) scale(0.8);
  }
  .whyus_slide_img.right {
    transform: translateX(670px) scale(0.8);
  }
  .prev {
    left: 23%;
  }
  .next {
    right: 23%;
  }
}
@media (max-width: 1200px) {
  .whyus_slide_img.left {
    transform: translateX(-470px) scale(0.8);
  }
  .whyus_slide_img.right {
    transform: translateX(470px) scale(0.8);
  }
  .prev {
    left: 23%;
    display: none;
  }
  .next {
    right: 23%;
    display: none;
  }
}
@media (max-width: 1200px) {
  .whyus_slide_container {
    height: 242px;
  }
  .whyus_slide_img {
    height: 242px;
  }
  .whyus_block {
    padding: 40px 0;
  }
  .whyus_title_block {
    margin-bottom: 40px;
  }
  .whyus_subtitle {
    padding: var(--spacing-xs, 4px) var(--spacing-xl, 16px);
  }
  .whyus_title {
    font-size: var(--Font-size-display-md, 24px);
    line-height: var(--Line-height-display-md, 28px);
  }
  .whyus_subtitle_text {
    font-size: var(--Font-size-text-sm, 12px);
  }
}

