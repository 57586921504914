@import "Assets/Fonts/stylesheet.css";

.customContainer {
  width: 1320px;
  margin: 0 auto;
}

@media (max-width: 1440px) {
  .customContainer {
    width: 1140px;
    margin: 0 auto;
  }
}
@media (max-width: 1200px) {
  .customContainer {
    width: 720px;
    margin: 0 auto;
  }
}
@media (max-width: 720px) {
  .customContainer {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 720px) {
  .customContainer_mobile {
    width: 100%;
    margin: 0 auto;
  }
}

.customContainerFaq {
  width: 840px;
  margin: 0 auto;
}

@media (max-width: 1440px) {
  .customContainerFaq {
    width: 840px;
    margin: 0 auto;
  }
}
@media (max-width: 1200px) {
  .customContainerFaq {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 720px) {
  .customContainerFaq {
    width: 90%;
    margin: 0 auto;
  }
}


body::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}
body {
  overflow: auto;
  position: relative;
}
div {
  background-color: transparent; /* Задає повністю прозорий фон */
}
