.container
  display: flex
  align-items: center
  gap: 48px
  position: relative
  padding: 64px 0 48px 0
.wrap
  justify-content: space-between
  display: flex
  align-items: center
  gap: 48px
  position: relative
  padding: 20px
  border-radius: var(--radius-3xl, 20px)
  border: 1px solid var(--Component-colors-Alpha-alpha-white-10, rgba(255, 255, 255, 0.10))
  background: var(--Component-colors-Alpha-alpha-white-10, rgba(255, 255, 255, 0.10))
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05))
  backdrop-filter: blur(3px)
.hidden
  overflow: hidden
  background-image: url("../../../Assets/Icons/CRM/CRMBg.webp")
  background-size: cover
  background-position: center
  padding-bottom: 96px
.image
  width: 829px
  height: 512px
  position: absolute
  transform: translate(90%, 0%)
.imageIcon
  width: 400px
  height: 360px
.subtitle
  padding: 7px 16px
  border-radius: var(--radius-full, 9999px)
  background: rgba(209, 227, 255, 0.10)
  margin-left: 38px
.subtitleContainer
  background: transparent
  border: none
.title
  color: var(--Colors-Text-text-primary_on-brand, #FFF)
.description
  color: #98A2B3
.benefitItem
  color: #FFF
.servicesText
  margin-bottom: 4px
.name
  color: var(--Colors-Text-text-white, #FFF)
.reviewCard
  max-width: 630px
.text
  color: var(--Colors-Text-text-white, #FFF)
.rating
  border-radius: var(--radius-full, 9999px)
  background: rgba(152, 162, 179, 0.20)
  padding: 4px 8px
  width: auto
@media (max-width: 1200px)
  .imageIcon
    display: none
@media(max-width: 720px)
  .container
    padding: 40px 0 32px 0
    flex-direction: column
  .image
    position: relative
    transform: translate(0%, 0%)
    width: 100%
    height: auto
  .wrap
    padding: 0
  .hidden
    padding-bottom: 40px
