.contactSection {
  text-align: center;
  height: 350px;
  display: flex;
  align-items: center;
  background: url("../../Assets/Images/Form/FormBg.webp") no-repeat;
  background-size: cover;
}

.title {
  color: var(--Colors-Text-text-white, #FFF);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: var(--Font-size-display-sm, 30px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-display-sm, 38px);
  margin: 0;
}

.subtitle {
  color: var(--Colors-Text-text-white, #FFF);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.32px;
  margin-bottom: 32px;
}

.form {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: flex-end;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 33%;
}

.label {
  color: white;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 10px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}
.input:focus {
  outline: none;
}
.input > :nth-child(1) > :nth-child(1) {
  border: none !important;
  height: 24px !important;
}
.input > :nth-child(2) {
  height: 24px !important;
  width: 100%;
  border: none !important;
  color: #7E818B !important;
  font-family: "Gilroy", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  border-radius: 6px !important;
}

.valid {
  box-shadow: inset 0px 0px 5px 5px rgba(100, 255, 100, 0.5);
}

.invalid {
  box-shadow: inset 0px 0px 5px 5px rgba(255, 0, 0, 0.5);
}

.button {
  height: 40px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  width: 33%;
  border-radius: var(--radius-full, 9999px);
  background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #0085FF);
  border: none;
}

.button:hover {
  background-color: #0056b3;
}

@media (max-width: 720px) {
  .contactSection {
    height: auto;
    background: #00143D;
    padding: 40px 0;
  }
  .form {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .formGroup {
    width: 100%;
  }
  .button {
    width: 100%;
  }
  .title {
    font-size: 24px;
  }
  .subtitle {
    font-size: 16px;
  }
  .button {
    margin-top: 4px;
  }
}

